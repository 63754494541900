import React, { useEffect, useState } from "react";
import LoadingCircle from "../utility/LoadingCircle";

const ClockinInfoModal = ({ clockInLogic, api, token, closeModal, note, setNote }) => {
	const [isDriver, setIsDriver] = useState(null);
	const [hasPPE, setHasPPE] = useState(null);
	const [searchItem, setSearchItem] = useState("");
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [filteredItems, setFilteredItems] = useState([]);
	const [clockinEnabled, setClockinEnabled] = useState(false);
	const [loading, setLoading] = useState(true);
	const [bounceVehicles, setBouncieVehicles] = useState([]);
	const [usingPersonalVehicle, setUsingPersonalVehicle] = useState(null);
	//
	useEffect(() => {
		if (token === "") {
			return;
		}
		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		fetch(api + "/clocking/vehicles", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((data) => {
				if (data && data.vehicles) {
					setBouncieVehicles(data.vehicles);
					setLoading(false);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, token]);

	useEffect(() => {
		setFilteredItems(bounceVehicles.filter((item) => item.toLowerCase().includes(searchItem.toLowerCase())));
	}, [bounceVehicles, searchItem]);

	const handleItemSelect = (item) => {
		setSearchItem(item);
		setFilteredItems([]);
		setDropdownVisible(false);
	};

	useEffect(() => {
		if (typeof hasPPE === "boolean" && typeof isDriver === "boolean") {
			if (hasPPE === false) {
				setClockinEnabled(false);
			} else if (usingPersonalVehicle) {
				setClockinEnabled(true);
			} else if (searchItem !== null && searchItem !== undefined && bounceVehicles.includes(searchItem)) {
				setClockinEnabled(true);
			} else {
				setClockinEnabled(false);
			}
		} else {
			setClockinEnabled(false);
		}
	}, [bounceVehicles, hasPPE, isDriver, searchItem, usingPersonalVehicle]);

	return (
		<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/[.3] z-[200]" onClick={closeModal}>
			<div
				className="p-4 bg-white rounded-lg w-full md:w-2/5"
				onClick={(e) => {
					e.stopPropagation();
					setDropdownVisible(false);
				}}>
				{loading ? (
					<div className="flex flex-col justify-center items-center">
						<LoadingCircle />
						<p className="text-center mt-2">Grabbing Vehicles...</p>
					</div>
				) : (
					<div className="flex flex-col justify-center items-center">
						<div className="w-full flex justify-between items-center">
							<p className="font-bold text-2xl">Clock In Form</p>
							<button className="bg-gray-400 hover:bg-gray-600 p-2 rounded-lg text-white" onClick={closeModal}>
								Close
							</button>
						</div>
						<form className="w-full mt-4">
							<div className="mb-4">
								<label className="block font-semibold mb-2">Are you using a personal vehicle?</label>
								<div className="flex space-x-4">
									<label className="flex items-center">
										<input
											type="radio"
											name="personalVehicle"
											value="yes"
											checked={usingPersonalVehicle === true}
											onChange={() => setUsingPersonalVehicle(true)}
										/>
										<span className="ml-2">Yes</span>
									</label>
									<label className="flex items-center">
										<input
											type="radio"
											name="personalVehicle"
											value="no"
											checked={usingPersonalVehicle === false}
											onChange={() => setUsingPersonalVehicle(false)}
										/>
										<span className="ml-2">No</span>
									</label>
								</div>
							</div>
							{usingPersonalVehicle === false ? (
								<div className="mb-4 relative" onClick={(e) => e.stopPropagation()}>
									<label htmlFor="item-search" className="block font-semibold mb-2">
										Select Company Vehicle
									</label>
									<input
										type="text"
										id="item-search"
										className="w-full p-2 border rounded border-black"
										placeholder="Type to search..."
										value={searchItem}
										onChange={(e) => setSearchItem(e.target.value)}
										onFocus={() => setDropdownVisible(true)}
									/>
									{dropdownVisible ? (
										<div className="absolute z-10 w-full bg-white border border-black rounded mt-1 max-h-40 overflow-y-auto">
											{filteredItems.map((item, index) => (
												<div key={index} className="p-2 hover:bg-gray-200 cursor-pointer" onMouseDown={() => handleItemSelect(item)}>
													{item}
												</div>
											))}
										</div>
									) : null}
								</div>
							) : null}

							<div className="mb-4">
								<label className="block font-semibold mb-2">Are you the driver?</label>
								<div className="flex space-x-4">
									<label className="flex items-center">
										<input type="radio" name="driver" value="yes" checked={isDriver === true} onChange={() => setIsDriver(true)} />
										<span className="ml-2">Yes</span>
									</label>
									<label className="flex items-center">
										<input type="radio" name="driver" value="no" checked={isDriver === false} onChange={() => setIsDriver(false)} />
										<span className="ml-2">No</span>
									</label>
								</div>
							</div>
							<div className="mb-4">
								<label className="block font-semibold mb-2">Do you have your PPE?</label>
								<div className="flex space-x-4">
									<label className="flex items-center">
										<input type="radio" name="ppe" value="yes" checked={hasPPE === true} onChange={() => setHasPPE(true)} />
										<span className="ml-2">Yes</span>
									</label>
									<label className="flex items-center">
										<input type="radio" name="ppe" value="no" checked={hasPPE === false} onChange={() => setHasPPE(false)} />
										<span className="ml-2">No</span>
									</label>
								</div>
								{hasPPE === false ? (
									<p className="text-red-500 mt-2">
										Warning: You may not clock into a job site without PPE. Contact your ops manager for the next steps.
									</p>
								) : null}
							</div>

							<div>
								<p>Notes:</p>
								<textarea
									value={note}
									onChange={(e) => setNote(e.target.value)}
									placeholder=""
									rows="4"
									className="w-full p-1 border border-black"
									maxLength={500}
								/>
								<div className="text-xs float-right">{`${note.length}/500`}</div>
							</div>
							<button
								type="button"
								className={`w-full p-2 mt-4 rounded ${clockinEnabled ? "bg-blue-500 text-white" : "bg-gray-500 text-white cursor-not-allowed"}`}
								onClick={clockinEnabled ? () => clockInLogic(hasPPE, isDriver, searchItem, usingPersonalVehicle) : null}
								disabled={!clockinEnabled}>
								Clock In
							</button>
						</form>
					</div>
				)}
			</div>
		</div>
	);
};

export default ClockinInfoModal;
