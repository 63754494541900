import { Link } from "react-router-dom";
import LoadingCircle from "../utility/LoadingCircle";

import { PaginationNav1Presentation } from "./Pagination";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useEffect, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";

const ProjectList = ({ title, projectList, isLoading, isFullWidth, setProjectList, isPublicStorage }) => {
	const [contractSort, setContractSort] = useState(0);
	const [templateSort, setTemplateSort] = useState(0);

	const [pageCount, setPageCount] = useState(0);
	const [firstIndex, setFirstIndex] = useState(0);
	const [lastIndex, setLastIndex] = useState(0);
	const [globalPage, setGlobalPage] = useState(0);

	useEffect(() => {
		if (title !== "Recent Projects") {
			setPageCount(Math.ceil(projectList.length / 20));
			setFirstIndex(globalPage * 20);
			setLastIndex(20 * (globalPage + 1) < projectList.length ? 20 * (globalPage + 1) - 1 : projectList.length - 1);
		} else {
			setPageCount(Math.ceil(projectList.length / 20));
			setFirstIndex(0);
			setLastIndex(20 * 1 < projectList.length ? 20 - 1 : projectList.length - 1);
		}
	}, [projectList, globalPage, title]);

	const sortByProject = (e) => {
		e.preventDefault();

		if (contractSort === 0) {
			projectList.sort(function (a, b) {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
		}
		if (contractSort === 1) {
			projectList.sort(function (a, b) {
				if (a.name > b.name) {
					return -1;
				}
				if (a.name < b.name) {
					return 1;
				}
				return 0;
			});
		}

		setProjectList([...projectList]);
		if (contractSort === 0) {
			setContractSort(1);
		} else {
			setContractSort(0);
		}
	};

	const sortByTemplate = (e) => {
		e.preventDefault();

		if (templateSort === 0) {
			projectList.sort(function (a, b) {
				if (a.template_name < b.template_name) {
					return -1;
				}
				if (a.template_name > b.template_name) {
					return 1;
				}
				return 0;
			});
		}
		if (templateSort === 1) {
			projectList.sort(function (a, b) {
				if (a.template_name > b.template_name) {
					return -1;
				}
				if (a.template_name < b.template_name) {
					return 1;
				}
				return 0;
			});
		}

		setProjectList([...projectList]);

		if (templateSort === 0) {
			setTemplateSort(1);
		} else {
			setTemplateSort(0);
		}
	};

	return (
		<div className={`${!isFullWidth ? "md:basis-3/4" : "w-full"} text-center `}>
			<div className="flex justify-between items-center bg-gray-300 border border-black p-4 rounded-t-lg">
				<div className="hidden md:flex basis-1/3"></div>
				<p className="text-2xl basis1/2 md:basis-1/3 text-center p-3  ">{title ? title : null}</p>
				<div className="basis-1/2 md:basis-1/3">
					{isPublicStorage ? null : (
						<div className="flex justify-end">
							<Link to="/export-form" className="bg-gray-50 border border-1 border-black p-3 rounded-lg flex items-center justify-center">
								<AiOutlineUpload size={30} /> <span>Export Multiple</span>
							</Link>
						</div>
					)}{" "}
				</div>
			</div>
			<div className="gap-3 w-full border border-black p-2 hidden md:flex justify-between bg-black text-white">
				{title === "Recent Projects" ? (
					<p className="md:basis-1/4">Project</p>
				) : (
					<div className="md:basis-1/4 flex justify-center">
						<p className="display:inline text-right">Project</p>
						<button className="ml-1">
							{contractSort === 0 ? (
								<IoChevronUp className="cursor-pointer w-auto h-5" onClick={sortByProject} />
							) : (
								<IoChevronDown className="cursor-pointer w-auto h-5" onClick={sortByProject} />
							)}
						</button>
					</div>
				)}
				<p className="md:basis-1/4">Template</p>

				<p className="md:basis-1/4">Contract</p>
				<p className="md:basis-1/4">Stage</p>

				{title === "Recent Projects" ? (
					<p className="md:basis-1/4">Last Modified</p>
				) : (
					<div className="md:basis-1/4 flex justify-center">
						<p className="display:inline text-right">Template Name</p>
						<button className="ml-1">
							{templateSort === 0 ? (
								<IoChevronUp className="cursor-pointer w-auto h-5" onClick={sortByTemplate} />
							) : (
								<IoChevronDown className="cursor-pointer w-auto h-5" onClick={sortByTemplate} />
							)}
						</button>
					</div>
				)}
			</div>
			<div>
				{projectList.length > 0 ? (
					projectList.map((value, index) => {
						if (index <= lastIndex && index >= firstIndex) {
							return (
								<div
									key={value.id}
									className={
										"gap-3 border border-black p-3 flex flex-col md:flex-row justify-between items-center " +
										(index % 2 === 0 ? "bg-white" : "bg-gray-200") +
										(index === projectList.length - 1 ? " rounded-b-lg " : "")
									}>
									<div className="md:basis-1/2">
										<Link to={"/project/" + value.id} className="text-blue-600 underline ">
											{value.name}
										</Link>
									</div>
									<p className="md:basis-1/2">{value.template_name}</p>
									<p className="md:basis-1/2">{value.contract ? value.contract : "no contract"}</p>
									<p className="md:basis-1/2">{value.stage}</p>

									{title !== "Recent Projects" ? (
										<p className="md:basis-1/2">{value.template_name + " - v" + value.version}</p>
									) : (
										<p className="md:basis-1/2">{new Date(value.last_modified).toLocaleString("en-US")}</p>
									)}
								</div>
							);
						}
					})
				) : isLoading ? (
					<div className="flex flex-col items-center justify-center w-full my-2">
						<LoadingCircle />
						<p>Loading recent projects</p>
					</div>
				) : (
					<p>An error occurred when getting recent projects</p>
				)}

				{title !== "Recent Projects" ? (
					<div className="text-center">
						<span className="text-sm text-gray-700 dark:text-gray-400">
							Showing
							<span className="font-semibold text-gray-900"> {globalPage * 20 + 1}</span> to
							<span className="font-semibold text-gray-900">
								{" "}
								{20 * (globalPage + 1) < projectList.length ? 20 * (globalPage + 1) : projectList.length}
							</span>{" "}
							of
							<span className="font-semibold text-gray-900"> {projectList.length}</span> Entries
						</span>

						<nav aria-label="Page Navigation">
							<ul className="inline-flex items-center -space-x-px h-16">
								{PaginationNav1Presentation(pageCount, "", globalPage, setGlobalPage)}
							</ul>
						</nav>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default ProjectList;
