import FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import LoadingCircle from "../../utility/LoadingCircle";
import ExportManyModal from "./ExportManyModal";

const ExportForm = ({ api, token }) => {
	const [projects, setProjects] = useState([]);
	const [selectedProjects, setSelectedProjects] = useState([]);
	const [showAddProjectModal, setShowAddProjectModal] = useState(false);
	const [isExporting, setIsExporting] = useState(false);
	const [exportFailed, setExportFailed] = useState(false);
	const [dataExported, setDataExported] = useState(0);

	useEffect(() => {
		document.title = "Export Form";
	}, []);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		fetch(api + "/project/all-projects", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					setProjects(jsonData.projects);
				}
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, token]);

	const formatCSVHeader = (theString) => {
		return `"${theString.replaceAll('"', "'").replaceAll(" ", "_")}"`;
	};

	const formatCSVData = (theData) => {
		let formattedData = typeof theData === "string" ? `${theData.replaceAll('"', "'")}` : theData;
		formattedData = typeof formattedData === "undefined" || formattedData === null ? "" : formattedData;
		return `"${formattedData}"`;
	};

	const chunkArray = (array, chunkSize) => {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunks.push(array.slice(i, i + chunkSize));
		}
		return chunks;
	};

	const exportDataInBatches = async () => {
		if (isExporting) {
			return;
		}
		setIsExporting(true);
		setDataExported(0);
		let projectIds = selectedProjects.map((mapData) => mapData.project_id);
		let projectIdChunks = [];
		let maxChunk = 10;
		projectIdChunks = chunkArray(projectIds, maxChunk);

		let projectChunks = [];
		for (let i = 0; i < projectIdChunks.length; i++) {
			let projectData = await fetch(api + "/project/multiple-csv", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: token,
				},
				body: JSON.stringify({ project_ids: projectIdChunks[i] }),
			});

			if (projectData.ok) {
				let jsonData = await projectData.json();
				setDataExported((prev) => prev + projectIdChunks[i].length);
				projectChunks.push(...jsonData.data);
			} else {
				setIsExporting(false);
				setExportFailed(true);
				break;
			}
		}
		exportData(projectChunks);
	};

	const exportData = (data) => {
		let allTemplates = {};

		for (let i = 0; i < data.length; i++) {
			let currentFields = [];
			for (let j = 0; j < data[i].length; j++) {
				currentFields.push(formatCSVHeader(data[i][j].field_name + "_PMS"));
				if (data[i][j].odoo_mapping !== "") {
					currentFields.push(formatCSVHeader(data[i][j].field_name + "_Odoo"));
				}
			}
			let currentSections = data[i].map((mapData) => formatCSVData(mapData.section_name));
			let templateNames = data[i].map((mapData) => mapData.template_name);
			templateNames = [...new Set(templateNames)];
			if (templateNames.length > 1) {
			} else {
				templateNames = templateNames[0];
			}
			if (!(templateNames in allTemplates)) {
				allTemplates[templateNames] = {
					sections: [...currentSections],
					fields: [...currentFields],
				};
			} else {
				allTemplates[templateNames].sections.push(...currentSections);
				allTemplates[templateNames].fields.push(...currentFields);
			}
		}
		let headers = [];
		for (const template in allTemplates) {
			allTemplates[template].fields = [...new Set(allTemplates[template].fields)];
			allTemplates[template].sections = [...new Set(allTemplates[template].sections)];
			headers.push(...allTemplates[template].fields);
		}

		headers = ["project_name_PMS", "section_name_PMS", ...new Set(headers)];
		let formattedData = [];
		for (let i = 0; i < data.length; i++) {
			formattedData.push({});
			for (let j = 0; j < data[i].length; j++) {
				let sectionName = formatCSVData(data[i][j].section_name);
				let fieldName = formatCSVHeader(data[i][j].field_name + "_PMS");
				let odooFieldName = formatCSVHeader(data[i][j].field_name + "_Odoo");
				let projectName = formatCSVData(data[i][j].project_name);
				let odooValue = formatCSVData(data[i][j].odoo_value === "failedtogetdataforpmscode400" ? "" : data[i][j].odoo_value);
				let dbValue = formatCSVData(data[i][j].db_value);
				if (data[i][j].field_type === "yes/no") {
					if (data[i][j].db_value === 1 || data[i][j].db_value === "1") {
						dbValue = formatCSVData("yes");
					} else if (data[i][j].db_value === 0 || data[i][j].db_value === "0") {
						dbValue = formatCSVData("no");
					}
				}
				if (!(sectionName in formattedData[i])) {
					let rowLength = headers.length;
					let defaultRow = Array(rowLength).join(".").split(".");
					formattedData[i][sectionName] = [projectName, sectionName, ...defaultRow];
				}
				let fieldPos = headers.indexOf(fieldName);
				formattedData[i][sectionName][fieldPos] = dbValue;
				fieldPos = headers.indexOf(odooFieldName);
				formattedData[i][sectionName][fieldPos] = odooValue;
			}
		}

		let rows = [[headers]];
		for (const index in formattedData) {
			for (const sections in formattedData[index]) {
				rows.push(formattedData[index][sections]);
			}
		}
		let csvContent = "";
		rows.forEach((rowArray) => {
			let row = rowArray.join(",");
			csvContent += row + "\r\n";
		});

		const fileData = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
		FileSaver.saveAs(fileData, "pms-data-export.csv");
		setIsExporting(false);
	};

	const removeProject = (e, projectToRemove) => {
		e.preventDefault();
		let currentProjects = [...selectedProjects];
		currentProjects = currentProjects.filter((projectData) => projectData !== projectToRemove);
		setSelectedProjects([...currentProjects]);
	};

	return (
		<div className="flex flex-col">
			<div className="bg-gray-100 p-4 text-lg md:text-2xl font-bold">
				<p>Export Form</p>
			</div>
			{showAddProjectModal ? (
				<ExportManyModal
					projects={projects}
					selectedProjects={selectedProjects}
					setSelectedProjects={setSelectedProjects}
					setShowAddProjectModal={setShowAddProjectModal}
				/>
			) : null}
			<div className="flex w-full justify-center p-4">
				<div className="w-full md:w-4/5">
					<div className="md:w-full border border-black border-2 rounded-lg">
						<div className="md:w-full rounded-t-lg bg-gray-300">
							<div className="flex justify-center items-center p-4  rounded-t-lg">
								<div className="flex gap-4">
									<p className="text-2xl font-bold text-center ">Projects to Export</p>
									<button
										className="border border-black border-2 rounded-lg p-1 flex items-center bg-white"
										onClick={() => setShowAddProjectModal(true)}>
										Add Project
									</button>
									<button
										className="border border-black border-2 bg-red-400 text-white rounded-lg p-1 flex items-center bg-white"
										onClick={() => setSelectedProjects([])}>
										Clear All Projects
									</button>
								</div>
							</div>
							<hr className="border-black" />
						</div>
						<div className="h-[60vh] overflow-y-scroll">
							<div className=" ">
								{selectedProjects && selectedProjects.length > 0 ? (
									selectedProjects.map((mapData, index) => {
										return (
											<div key={mapData.project_id}>
												<div className="p-4 flex justify-between items-center select-none">
													<div className="">
														<p className="font-bold">{mapData.project_name}</p>
														<p>
															{mapData.template_name} - v{mapData.template_version}
														</p>
													</div>
													<BsTrash className="text-xl cursor-pointer" color="red" onClick={(e) => removeProject(e, mapData)} />
												</div>
												{index !== selectedProjects.length - 1 ? <hr className="border-black" /> : null}
											</div>
										);
									})
								) : (
									<p className="text-center p-4">No projects selected</p>
								)}
							</div>
						</div>
						<hr className="border-black" />
						<div className="text-center p-4 bg-gray-300 rounded-b-lg">
							{selectedProjects && selectedProjects.length > 0 ? (
								<button
									className="text-white bg-blue-600 p-4 rounded-lg"
									onClick={() => {
										if (isExporting) {
											return;
										}
										setExportFailed(false);
										exportDataInBatches();
									}}>
									{isExporting ? (
										<div className="flex flex-col justify-center items-center">
											<LoadingCircle />
											<p>Exporting...</p>
											<p>{`${dataExported}/${selectedProjects.length}`}</p>
										</div>
									) : (
										<p>Export To CSV</p>
									)}
								</button>
							) : (
								<button className="text-white bg-gray-600 p-4 rounded-lg">Export To CSV</button>
							)}
							{exportFailed ? <p className="text-red-600">Something went wrong while exporting. Please try again.</p> : null}
							<p># of Projects: {selectedProjects && selectedProjects.length}</p>
							<p># of Templates: {selectedProjects && [...new Set(selectedProjects.map((mapData) => mapData.template_name))].length}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExportForm;
