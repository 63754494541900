import { useEffect, useState } from "react";
import { getADPFormattedTimestamp } from "./ClockinUtils";
import ClockInModal from "./ClockinModal";
import { FaWalking } from "react-icons/fa";
import TimeSinceDate from "./TimeSinceDate";
import { IoMdWarning } from "react-icons/io";
import ClockinInfoModal from "./ClockinInfoModal";
import ClockingNotesModal from "./ClockingNotesModal";

const ClockInButton = ({
	api,
	token,
	odooId,
	odooModel,
	projectsRoute,
	clockingData,
	setClockingData,
	clockedIntoThis,
	isFullWidth = false,
	showClock = false,
	clockBannerToShow,
	setClockBannerToShow,
}) => {
	const [conflictingProject, setConflictingProject] = useState(false);
	const [clockingIn, setClockingIn] = useState(false);
	const [responseCode, setResponseCode] = useState(-1);
	const [showModal, setShowModal] = useState(false);
	const [conflictIdMatchesClockInId, setConflictIdMatchesClockInId] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [showOvertimeModal, setShowOvertimeModal] = useState(false);
	const [showClockinInfoModal, setShowClockinInfoModal] = useState(false);
	const [clockinFormData, setClockinFormData] = useState(null);
	const [note, setNote] = useState("");
	const [showNoteModal, setShowNoteModal] = useState(false);

	const clockIn = (transferOnConflict = false, attemptedClockinData = false) => {
		if (clockingData && clockingData.specialCode === "meal-in" && clockingData.lastClocked) {
			const currentDate = new Date();
			const differenceInMilliseconds = currentDate - clockingData.lastClocked;
			const differenceInMinutes = differenceInMilliseconds / 1000 / 60;

			if (differenceInMinutes < 30) {
				setErrorMessage(
					`Unable to clock in before the end of your 30 minute lunch. ${
						30 - differenceInMinutes < 1 ? "Less than 1 minute remaining." : (30 - differenceInMinutes).toFixed(0) + " minute(s) remaining."
					} `
				);
				setResponseCode(400);
				setShowModal(true);
				setClockinFormData(attemptedClockinData);
				return;
			}
		}
		setShowOvertimeModal(false);
		setClockingIn(true);
		setShowModal(true);
		let newClockinTime = getADPFormattedTimestamp();

		let clockinFormDataToSend = attemptedClockinData;
		if (clockinFormDataToSend === false && clockinFormData) {
			clockinFormDataToSend = clockinFormData;
		}

		fetch(api + "/clocking/in", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				odooId: odooId,
				odooModel: odooModel,
				ADPTimestamp: newClockinTime,
				transferOnConflict: transferOnConflict,
				clockinFormData: clockinFormDataToSend,
				isClockIn: clockingData.specialCode !== "meal-in",
				note: note,
			}),
		})
			.then((res) => {
				setResponseCode(res.status);
				if (res.ok || res.status === 409 || res.status === 400) {
					return res.json();
				} else {
					setErrorMessage("An Unexpected error occurred. Please try again. If this persists, please contact IT.");
					setClockingIn(false);
				}
			})
			.then((data) => {
				if (data) {
					if (Object.keys(data).includes("clockingInfo")) {
						setClockingData({
							clockingInfo: data.clockingInfo,
							specialCode: data.specialCode,
							lastClocked: data.lastClocked ? new Date(data.lastClocked) : null,
						});
						setClockBannerToShow("");
						setClockinFormData(null);
						setShowClockinInfoModal(false);
						setNote("");
						setShowNoteModal(false);
					}
					if (data.error) {
						if (clockinFormDataToSend) {
							setClockinFormData(clockinFormDataToSend);
						}
						setErrorMessage(data.error);
						if (data.conflictedInstallation) {
							setConflictingProject(data.conflictedInstallation);
							setConflictIdMatchesClockInId(data.conflictIdMatchesClockInId);
						} else {
							//something else went wrong here
						}
					} else {
						setErrorMessage("");
					}
				}
				setClockingIn(false);
			})
			.catch((err) => {
				setResponseCode(400);
				setErrorMessage("An Unexpected error occurred. Please try again. If this persists, please contact IT.");
				setClockingIn(false);
			});
	};

	const clockInWithTransfer = () => {
		clockIn(true);
	};

	const checkOvertimeOnClick = (e) => {
		e.preventDefault();
		if (clockingData && clockingData.lastClocked && clockingData.specialCode === "clock-out" && clockBannerToShow === "over") {
			setShowOvertimeModal(true);
		} else {
			if (clockingData.specialCode !== "meal-in") {
				setShowClockinInfoModal(true);
			} else {
				setShowNoteModal(true);
			}
		}
	};

	const clockinWithFormData = (hasPPE, isDriver, searchItem, usingPersonalVehicle) => {
		let formData = { hasPPE, isDriver, searchItem, usingPersonalVehicle };
		clockIn(false, formData);
	};

	return (
		<div>
			{showNoteModal ? (
				<ClockingNotesModal
					closeModal={() => {
						setShowNoteModal(false);
					}}
					note={note}
					setNote={setNote}
					buttonLogic={() => {
						clockIn(false);
					}}
					buttonText={"Meal In"}
				/>
			) : null}
			{showClockinInfoModal ? (
				<ClockinInfoModal
					api={api}
					token={token}
					closeModal={() => setShowClockinInfoModal(false)}
					clockInLogic={clockinWithFormData}
					note={note}
					setNote={setNote}
				/>
			) : null}
			{showOvertimeModal ? (
				<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/[.3] z-[200]">
					<div className="p-4 bg-white rounded-lg w-full md:w-1/5">
						<div className="flex flex-col justify-center items-center">
							<div className="flex justify-center items-center">
								<IoMdWarning size={55} color="red" />
							</div>
							<p className="text-red-700">Warning</p>

							<p className="mt-1 text-center">
								You have been working for 6 or more hours on another site. Please clock out of your previous site before clocking into this one.
							</p>
							<div className="w-full">
								<button
									className="bg-gray-500 hover:bg-gray-600 p-3 rounded-lg text-white mt-1 w-full"
									onClick={() => setShowOvertimeModal(false)}>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			) : null}
			{showModal ? (
				<ClockInModal
					isLoading={clockingIn}
					odooId={odooId}
					conflictingProject={conflictingProject}
					status={responseCode}
					cancel={() => setShowModal(false)}
					clockInWithTransfer={clockInWithTransfer}
					projectsRoute={projectsRoute}
					conflictIdMatchesClockInId={conflictIdMatchesClockInId}
					loadingMessage={"Clocking in..."}
					successMessage={"You Clocked In."}
					errorMessage={errorMessage}
				/>
			) : null}
			{clockedIntoThis && clockingData.specialCode === "clock-out" ? null : (
				<div className="">
					{clockingData && clockingData.lastClocked && showClock && clockedIntoThis ? (
						<TimeSinceDate
							date={clockingData.lastClocked}
							preText={clockingData && clockingData.specialCode === "meal-in" ? "Out for a Meal at" : "Ended Work at"}
							setClockBannerToShow={setClockBannerToShow}
							specialCode={clockingData.specialCode}
						/>
					) : clockingData && clockingData.lastClocked && clockBannerToShow !== "over" ? (
						<div className="hidden">
							<TimeSinceDate
								date={clockingData.lastClocked}
								preText={""}
								setClockBannerToShow={setClockBannerToShow}
								specialCode={clockingData.specialCode}
							/>
						</div>
					) : null}
					<button
						className={`p-2 border border-blue-500 text-white ${
							showModal ? "bg-gray-600" : "bg-blue-600 hover:bg-blue-700"
						} rounded-lg flex items-center justify-center gap-1 w-full`}
						onClick={checkOvertimeOnClick}>
						<FaWalking />

						{clockingData && clockingData.specialCode === "meal-in" ? "Meal In" : "Clock in"}
					</button>
				</div>
			)}
		</div>
	);
};

export default ClockInButton;
