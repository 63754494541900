const ProductOrderCard = ({ item }) => {
	const product = item.product;
	if (!product) {
		return (
			<div className="p-4 border border-black">
				<p>Invalid product</p>
			</div>
		);
	}
	return (
		<>
			<div className="p-2 border border-black flex flex-col justify-between">
				<div className="flex justify-center items-center">
					<img src={`data:image/jpeg;base64,${product.image_1920}`} className="h-[100px] w-auto" alt={product.name} />
				</div>
				<p className="font-bold text-sm">{product.name}</p>
				<div className="flex gap-1">
					{product.product_template_variant_value_ids
						? product.product_template_variant_value_ids.map((variant) => {
								return (
									<div key={variant} className="px-1 border border-black">
										<p className="text-sm">{variant}</p>
									</div>
								);
						  })
						: null}
				</div>

				<p className="text-xs mt-1">
					<span className="font-bold">Amount ordered:</span> {item.product_uom_qty}
				</p>
				{item.x_studio_notes ? (
					<p className="mt-2 text-sm whitespace-pre-wrap">
						<span className="font-bold">Notes: </span>
						{item.x_studio_notes}
					</p>
				) : null}
			</div>
		</>
	);
};

export default ProductOrderCard;
