import { useEffect, useState } from "react";
import { HiPresentationChartBar } from "react-icons/hi";
import ReportModal from "../ReportModal";

const ReportButton = ({ api, token, odooId, selectedOdooModel, templateNavMappings, relatedOdooRecords, projectName }) => {
	const [showReportModal, setShowReportModal] = useState(false);
	const [reports, setReports] = useState([]);
	const [requests, setRequests] = useState();
	useEffect(() => {
		if (isNaN(odooId)) {
			return;
		}
		fetch(api + "/report/project/odoo/" + odooId, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: token,
			},
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((data) => {
				if (data) {
					setReports(data.reports);
					setRequests(data.requests);
				}
			});
	}, [api, token, odooId]);
	const openReportModal = (e) => {
		e.preventDefault();
		setShowReportModal(true);
	};
	return (
		<>
			{showReportModal ? (
				<ReportModal
					setShowReportModal={setShowReportModal}
					reports={reports}
					api={api}
					token={token}
					odooId={odooId}
					requests={requests}
					selectedOdooModel={selectedOdooModel}
					templateNavMappings={templateNavMappings}
					relatedOdooRecords={relatedOdooRecords}
					projectName={projectName}
				/>
			) : null}
			<div className="flex items-center gap-1 bg-blue-600 hover:bg-blue-700 p-2 rounded-lg cursor-pointer w-full select-none" onClick={openReportModal}>
				<HiPresentationChartBar />
				<p className="text-center">Create Report</p>
			</div>
		</>
	);
};

export default ReportButton;
