import { Link } from "react-router-dom";

const OrderCard = ({ order }) => {
	const removeAutonums = (s) => {
		const regex = /(-\s?\d+)+$/;
		return s.replace(regex, "");
	};
	return (
		<div className="border border-black">
			<div className="bg-gray-200 border-b border-black  p-2">
				<Link to={"/order/" + order.id} className="font-bold text-lg text-blue-600 hover:text-blue-700 underline">
					{removeAutonums(order.name)}
				</Link>
			</div>

			<div className="p-2">
				<p>
					<span className="font-bold">Quotation Date:</span> {new Date(order.date_order).toLocaleDateString()}
				</p>
				<p>
					<span className="font-bold">Items Requested:</span> {order.order_line.length}
				</p>
				<p>
					<span className="font-bold">Module:</span> {order.x_studio_selection_field_TadkK}
				</p>
				<p>
					<span className="font-bold">Status:</span> {order.state}
				</p>
			</div>
		</div>
	);
};

export default OrderCard;
