import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingCircle from "../../utility/LoadingCircle";
import ProductOrderCard from "./ProductOrderCard";

const Order = ({ api, token }) => {
	const [loading, setLoading] = useState(false);
	const { id } = useParams();
	const [order, setOrder] = useState(null);
	const removeAutonums = (s) => {
		const regex = /(-\s?\d+)+$/;
		return s.replace(regex, "");
	};

	useEffect(() => {
		if (api === "" || token === "") {
			return;
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setLoading(true);
		fetch(`${api}/shop/order/${id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
				}
			})
			.then((res) => {
				if (res) {
					setOrder(res.order);
					document.title = "Order: " + removeAutonums(res.order.name);
				}
				setLoading(false);
			})
			.catch((err) => {});

		return () => {
			controller.abort();
		};
	}, [api, id, token]);

	if (loading) {
		return (
			<div className="fixed top-0 right-0 left-0 bottom-0 bg-black/50 z-[100] flex justify-center items-center">
				<div className="flex flex-col items-center justify-center border border-black p-4 bg-white">
					<LoadingCircle />
					<p>Loading Order...</p>
				</div>
			</div>
		);
	}
	return (
		<div className="w-full flex flex-col items-center">
			<h1 className="mt-3 font-bold text-3xl mb-3 mx-3">{removeAutonums(order ? "Order: " + order.name : "")}</h1>

			<div className="w-full md:w-3/5">
				{order ? (
					<div className=" mx-3 mb-3">
						<div className="flex gap-1">
							<p className="font-bold">BOM Odoo ID:</p>
							<p>{order.id}</p>
						</div>

						<div className="flex gap-1">
							<p className="font-bold">Module:</p>
							<p>{order.x_studio_selection_field_TadkK}</p>
						</div>

						<div className="flex gap-1">
							<p className="font-bold">Status:</p>
							<p>{order.state}</p>
						</div>

						<div className="flex gap-1">
							<p className="font-bold">Quotation Date:</p>
							<p>{new Date(order.date_order).toLocaleDateString()}</p>
						</div>

						<div className="flex flex-col gap-1">
							<div>
								<p className="font-bold mb-2">Products:</p>
								{order.order_line && order.order_line.length > 0 ? (
									<>
										<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-stretch">
											{order.order_line.map((mapData) => (
												<ProductOrderCard key={mapData.id} item={mapData} />
											))}
										</div>
									</>
								) : (
									<div>
										<p>No products in this order</p>
									</div>
								)}
							</div>
						</div>
					</div>
				) : (
					<div>
						<p>No order found</p>
					</div>
				)}

				{/* <p className="whitespace-pre-wrap">{JSON.stringify(order, null, 2)}</p> */}
			</div>
		</div>
	);
};

export default Order;
